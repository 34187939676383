//en.js
const hostname = window.location.hostname;
console.log("hostname",hostname);
const en = {
    "baseurl": hostname == "visitor.sialchina.com" ? "" : "/en",
    "lang": "en",
    "head_title": "sial shanghai|sial shenzhen-Visitor Member Center-SIAL China",
    "head_description": "SIAL Shanghai will be held at theShanghai New International Expo Center from May 19-21, 2025.The exhibition area will span a vast expanse of 200,000 square meters, and it is anticipated that 5,000 exhibitors from over 70 countires and regions, and more than 180,000 professionals from 110+countries will together join the show.SIAL Shenzhen will be held at the Shenzhen Convention & Exhibition Center from September 1-3, 2025. The exhibition area will cover 60,000 square meters, expecting to attract over 1,500 exhibitors and over 67,000 professionals from around the world.",
    "head_keywords": "sial shanghai,sial shenzhen,SIAL China",
    "￥": "$",
    "home": "Home",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "please select payment": "Please select a payment method",
    "select payment": "Select payment method",
    "paid success": "Paid success",
    "paid successfully": "Paid successfully",
    "none payment": "Unpaid",
    "paid": "Paid",
    "Wechat Pay": "Wechat Pay",
    "Alipay Pay": "Alipay Pay",
    "Offline payment": "Bank Transfer",
    "coupon": "Coupon",
    "input coupon": "Please enter your promotion code",
    "submit payment": "Immediate payment",
    "open Alipay payment interface": "Please pay in the open Alipay payment interface",
    "Bank Transfer Information": "Bank Transfer Information",
    "paying": "Paying...",
    "Pay with a swipe on wechat": "Pay with a swipe on wechat",
    "Tickets purchased successfully, please pay offline": "Tickets purchased successfully, please pay offline",
    "You have paid successfully, to My Ticket": 'You have paid successfully, please check the admission code on "My Ticket"!',
    "Warm reminder": "Warm reminder",
    "payment within 2 hours": "Please complete the payment within 2 hours, the order will be automatically cancelled.",
    "Order information": "Order information",
    "Order number": "Order number",
    "truename": "Truename",
    "mobile": "Mobile",
    "email": "Email",
    "number": "Number",
    "unit price": "Unit price",
    "total order price": "Total order price",
    "Discount amount": "Discount amount",
    "Amount actually paid": "Amount actually paid",
    "View order": "View order",
    "live time": "live time",
    "Please log in": "Please log in",
    "Log back in": "Log in",
    "The request timed out. Please refresh and try again": "The request timed out. Please refresh and try again",
    'date': {
        'D': 'Days',
        'h': 'hours',
        'm': 'Minutes',
        's': 'Seconds',
    },
    "livepay tips": "The video is valid for 21 days after purchase"
}
 
export default en