import {createStore} from 'vuex'
import user from "./modules/user";
import permission from "./modules/permission";
import eventlive from "./modules/eventlive";
import { getBeijingTimestamp } from "@/utils/common";

let currentLocale = "cn";
if (window.location.pathname.indexOf("/en") == 0 || window.location.hostname == "visitor.sialchina.com") {
  currentLocale = "en"
}

const store = createStore({
    state: {
        currentLang: currentLocale,
        domain: process.env.VUE_APP_HOST,
        domain_en: process.env.VUE_APP_EN_HOST,
        api_domain: currentLocale =="en" ? process.env.VUE_APP_API_EN_DOMAIN : process.env.VUE_APP_API_DOMAIN,

        sh_domain: "https://www.sialchina.cn/",
        sz_domain: "https://www.sialshenzhen.com/",
        sh_domain_en: "https://www.sialchina.com/",
        sz_domain_en: "https://www.sialchina.com/",
        visitor_domain: "https://visitor.sialchina.cn",
        qrcode_origin: `${currentLocale=="en" ? process.env.VUE_APP_API_EN_DOMAIN : process.env.VUE_APP_API_DOMAIN}/api/getQrcode?text=`,

        close_reg: getBeijingTimestamp() >= new Date("2024-09-01T07:00:00.000Z").getTime(), // 关闭注册
        // close_reg: true, // 关闭注册
        is_now_ids: [2], // 当前可以注册的展会id
    },
    modules: {
        user,
        permission,
        eventlive,
    },
    getters: {
        isLogin: state => state.user.isLogin,
        truename: state=>state.user.truename,
        is_need_completion_certificates: state => state.user.is_need_completion_certificates,
        is_need_completion_survey: state => state.user.is_need_completion_survey,
        expo_list: state => state.user.expo_list,
        roles: state => state.user.roles,
        hasAuth: state => state.permission.hasAuth,
        permissionRoutes: state => state.permission.routes,
        eventlive_show_login: state => state.eventlive.eventlive_show_login,
        eventlive_show_reg: state => state.eventlive.eventlive_show_reg,
        eventlive_show_finish: state => state.eventlive.eventlive_show_finish,
    },
    mutations: {
        SET_CURRENT_LANG(state, lang) {
            state.currentLang = lang
        },
    },
})

export default store